<template>
  <div>


    <!-- Filters -->

    <div class="row irisBorders irisHeader systemStatus" v-if="store.irisData.system_status > 1">{{store.status_dict[ store.irisData.system_status ]}}</div>
    <div v-if="store.irisData" class="row irisBorders irisHeader">
      <div v-if="!store.irisData.user.display_tc" class="col-md-2">
        <div class="form-group">
          <fg-input label="Date Range" v-if="store.irisData">
            <el-date-picker v-model="store.irisDateRange" type="daterange"
                          class="daterange-iris"
                          @change="setSummaryData()"
                          :start-placeholder="startDate"
                          :end-placeholder="endDate"
                          :default-value="startDate"
                          :picker-options="pickerOptions1">
            </el-date-picker>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.irisData.user.display_tc" class="col-md-2">
        <div v-if="store.irisData.user.is_staff" class="form-group">
          <fg-input label="Billing Entity" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.companySelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all', label:'All'}].concat(store.irisData.be_options)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
        <div v-else class="form-group">
          <fg-input label="Site" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.siteSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(siteOptions)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.irisData.user.display_tc" class="col-md-2">
        <div v-if="store.irisData.user.is_staff" class="form-group">
          <fg-input label="Site" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.siteSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(siteOptions)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
        <div v-else class="form-group">
          <fg-input label="Product" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.productSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.product_options)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.irisData.user.display_tc" class="col-md-2">
        <div v-if="store.irisData.user.is_staff" class="form-group">
          <fg-input label="Product" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.productSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.product_options)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
        <div v-else class="form-group">
          <fg-input label="Sub-Product" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.subProductSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(subProductOptions)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.irisData.user.display_tc" class="col-md-2">
        <div v-if="store.irisData.user.is_staff" class="form-group">
          <fg-input label="Sub-Product" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       @change="setSummaryData()"
                       size="large"
                       v-model="store.subProductSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(subProductOptions)"
                           class="select-default"
                           :value="option.label.toLowerCase()"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div v-if="!store.irisData.user.display_tc" class="col-md-2 iris-vcenter">
        <!-- OG btn <a class="iris-a" @click="resetIrisData()">Reset Filters</a> -->

        <button @click="resetIrisData()" class="btn btn-default btn-fill btn-wd btn-iris-reset">Reset Filters</button> 

      </div>
    </div>



    <!--Stats cards-->


    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" v-for="stats in statsCards">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="(stats.value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')">
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="matchedAdReqChartData"
                    chart-id="requests-chart"
                    :chart-options="irisOptions"
                    color="#2ba9cd" chart-title="TOTAL MONETIZABLE REQUESTS IN LAST 30 DAYS">
          <span slot="title" style="font-size=1em;">{{ statsCards[1].title }}</span>
          <badge slot="title-label" type="info">avg ( {{ matchedAdReqChart.data && Math.round(parseInt(statsCards[1].value) / matchedAdReqChart.data.labels.length).toLocaleString("en-US") }} )</badge>


          <!-- OG, working correctly
          <badge slot="title-label" type="info">avg ( {{ matchedAdReqChart.data.labels.length && Math.round(parseInt(statsCards[1].value) / matchedAdReqChart.data.labels.length).toLocaleString("en-US") }} )</badge>
          END -->


          <template slot="footer-title">Total: {{ statsCards[1].value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</template>

          <!-- OG button
          <p-button slot="footer-right" aria-label="add button" type="success" round icon size="sm">
            <i class="nc-icon nc-simple-add"></i>
          </p-button>
          END -->

        </chart-card>
      </div>

      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="impChartData"
                    chart-id="impressions-chart"
                    :chart-options="irisOptions"
                    color="#ef8156" chart-title="TOTAL IMPRESSIONS IN LAST 30 DAYS">
          <span slot="title">Impressions</span>
          <badge slot="title-label" type="danger">avg ( {{ impChart.data && Math.round(parseInt(statsCards[2].value) / impChart.data.labels.length).toLocaleString("en-US") }} )</badge>

          <template slot="footer-title">Total: {{ statsCards[2].value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</template>

          <!-- OG button
          <p-button slot="footer-right" aria-label="view button" type="danger" round icon size="sm">
            <i class="nc-icon nc-button-play"></i>
          </p-button>
          END -->

        </chart-card>
      </div>

      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="revenueChartData"
                    chart-id="revenue-chart"
                    :chart-options="revenueOptions"
                    chart-title="TOTAL REVENUE IN LAST 30 DAYS">
          <span slot="title">Revenue</span>
          <badge slot="title-label" type="success">avg ( {{ revenueChart.data && new Intl.NumberFormat("en-US",
            { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }
          ).format(parseInt(statsCards[3].value.replace('$','')) / revenueChart.data.labels.length) }} )</badge>

          <template slot="footer-title">Total: {{ statsCards[3].value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</template>
          
          <!-- OG button
          <p-button slot="footer-right" aria-label="view more button" type="info" round icon size="sm">
            <i class="nc-icon nc-alert-circle-i"></i>
          </p-button>
          END -->

        </chart-card>
      </div>
    </div>


    <div class="row">
      <div class="typography-line" v-if="store.irisData">
        <h6><span class="floatRight">Last Updated {{ new Date( Date.parse(store.irisData.last_update) ).toLocaleString("en-US", {timeZone: "America/Los_Angeles"}) }}</span></h6>
      </div>
    </div>


    <!-- Exportable table -->
    <iris-tables v-if="store.irisData && store.irisData.mundial" :setSummaryData="setSummaryData" :aggregateOptions="aggregateOptions"></iris-tables>

  </div>
</template>
<script>
  import { DatePicker } from 'element-ui'
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import { Badge } from 'src/components/UIComponents'
  import IrisTables from 'src/components/Dashboard/Views/Tables/IrisTables.vue'
  import { store } from 'src/store'
  
  const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
    callbacks: {
        label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
                label += ': ';
            }
            label += tooltipItem.yLabel.toLocaleString("en-US");
            return label;
        }
    }
  }, irisOptions = {
    tooltips: tooltipOptions,
    scales: {
           yAxes: [{
               ticks: {
	           padding : 5,
                   fontColor: "#9f9f9f",
                   // Include a dollar sign in the ticks
                   callback: function(value, index, values) {
                       return new Intl.NumberFormat("en-US", {
                         notation: "compact",
                         compactDisplay: "short",
                       }).format(value);
                   },
               },
               gridLines: {
                   display: true,
                   drawBorder: true,
                   drawOnChartArea: false		   
               },
           }],
           xAxes: [{
	       display: true,
               ticks: {
	           padding : 5,
                   fontColor: "#9f9f9f",
                   callback: function(value, index, values) {
		       const maxIdx = values.length - 1;
		       var tickSpread = Math.floor(maxIdx / 10);
		       tickSpread = tickSpread === 0 ? 1 : tickSpread;

		       return index % tickSpread === 0 ? value : '';

                   },
               },
	       gridLines: {
	           display: true,
                   drawBorder: true,
                   drawOnChartArea: false
               },
           }],
       },
  }, irisDataset = {
       title: 'Mundial Media',
       borderColor: '#6bd098',
       pointBackgroundColor: '#6bd098',
       pointRadius: 2,
       pointHoverRadius: 6,
       borderWidth: 3,
       label: 'Data Zero',
       lineTension: 0,
       fill: false,
       data: []
  }, revenueOptions = JSON.parse(JSON.stringify(irisOptions)),
  irisDate = store.irisDate(),
  maxDate = store.irisDate(),
  maxDateThresh = store.irisDate(),
  storeNameDict = {
    'date': 'irisDateRange',
    'product_type': 'productSelected',
    'product_subtype': 'subProductSelected',
    'billing_entity': 'companySelected',
    'site_name': 'siteSelected',
  };

  // Default Dates
  maxDateThresh.setTime(maxDateThresh.getTime() - 3600 * 1000 * 24);

  //
  // Update revenueOptions
  //
  revenueOptions.tooltips.callbacks.label = function(tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label || '';

      if (label) {
          //label += ': $';
          label += ': ';
      }
      //label += tooltipItem.yLabel.toLocaleString("en-US");
      //label += tooltipItem.yLabel.toFixed(2);
      label += new Intl.NumberFormat("en-US",
        { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }
      ).format(tooltipItem.yLabel);
      
      return label;
  }
  revenueOptions.scales.yAxes[0].ticks.callback = function(value, index, values) {
      return '$' + new Intl.NumberFormat("en-US", {
          notation: "compact",
          compactDisplay: "short",
      }).format(value);
  }
  revenueOptions.scales.xAxes[0].ticks.callback = function(value, index, values) {
      const maxIdx = values.length - 1;
      var tickSpread = Math.floor(maxIdx / 10);
      tickSpread = tickSpread === 0 ? 1 : tickSpread;

      return index % tickSpread === 0 ? value : '';

  }

  export default {
    components: {
      StatsCard,
      ChartCard,
      Badge,
      IrisTables,
      [DatePicker.name]: DatePicker,
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        store,
        irisOptions,
        revenueOptions,
        aggregateOptions: [{value:'total',label:'Total'},{value:'daily',label:'Daily'}],
        statsCards: [
          {
            type: 'info',
            icon: 'nc-icon nc-globe',
            title: 'Requests',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'info',
            icon: 'nc-icon nc-tv-2',
            title: 'Monetizable Requests',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: 'Impressions',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: 'Revenue',
            value: '$',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          }
        ],
        matchedAdReqChart: {
          data: false
        },
        impChart: {
          data: false
        },
        revenueChart: {
          data: false
        },
        pickerOptions1: {
          disabledDate (date) {
            return date > maxDateThresh
          },
          shortcuts: [{
            text: 'MTD',
            onClick (picker) {
              const monthToDate = irisDate.GetMonthToDate(),
              sDate = monthToDate['startDay'],
              eDate = monthToDate['endDay'] > maxDate ? maxDate : monthToDate['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.irisText = 'MTD'
            }
          },
          {
            text: 'Last 7 Days',
            onClick (picker) {
              const lastSeven = irisDate.GetLastSeven(),
              sDate = lastSeven['startDay'],
              eDate = lastSeven['endDay'] > maxDate ? maxDate : lastSeven['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.irisText = 'Last 7 Days'
            }
          },
          {
            text: 'Last 30 Days',
            onClick (picker) {
              const lastThirty = irisDate.GetLastThirty(),
              sDate = lastThirty['startDay'],
              eDate = lastThirty['endDay'] > maxDate ? maxDate : lastThirty['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.irisText = 'Last 30 Days'
            }
          },
          {
            text: 'Last Week',
            onClick (picker) {
              const lastWeek = irisDate.GetLastWeek(),
              sDate = lastWeek['startDay'],
              eDate = lastWeek['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.irisText = 'Last Week'
            }
          },
          {
            text: 'Last Month',
            onClick (picker) {
              const lastMonth = irisDate.GetLastMonth(),
              sDate = lastMonth['startDay'],
              eDate = lastMonth['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.irisText = 'Last Month'
            }
          }]
        },
        filters: [],
        irisText: 'Last 30 Days',
      }
    },
    methods: {
      reformatDate (tmpDate) {
        //console.log({tmpDate})

        return tmpDate ? [tmpDate.split('-')[2], tmpDate.split('-')[0], tmpDate.split('-')[1]].join('-') : tmpDate
      },
      compileSummaryCharts () {
        //const chartData = store.chartData,
        const mundialData = store.mundialData,
        mundialSummary = {
          'adreq': 0,
          'mon_adreq': 0,
          'imp': 0,
          'revenue': 0,
        }, dateDict = {},
        sigFig = 3,
        irisText = store.irisText,
        math_round = Math.round;
        let dateArr = [], adreqArr = [], monadreqArr = [],
        impArr = [], revenueArr = [];

        //console.log({chartData})
        //console.log({mundialData})
        //console.log({store})
        
        //for(let i = 0; i < chartData.length; i++) {
          //const tmpData = chartData[i];

        for(let i = 0; i < mundialData.length; i++) {
          const tmpData = mundialData[i];
          
          mundialSummary.adreq += tmpData['adreq']
          mundialSummary.mon_adreq += tmpData['mon_adreq']
          mundialSummary.imp += tmpData['imp']
          mundialSummary.revenue += tmpData['revenue']

          if ( tmpData['date'] in dateDict ) {
             const dateIdx = dateDict[tmpData['date']];

             adreqArr[ dateIdx ] += tmpData['adreq']
             monadreqArr[ dateIdx ] += tmpData['mon_adreq']
             impArr[ dateIdx ] += tmpData['imp']
             revenueArr[ dateIdx ] += math_round(tmpData['revenue'] * (10 ^ sigFig)) / (10 ^ sigFig)
          } else {
             const dateIdx = dateArr.length;
             dateDict[ tmpData['date'] ] = dateIdx

             adreqArr[ dateIdx ] = tmpData['adreq']
             monadreqArr[ dateIdx ] = tmpData['mon_adreq']
             impArr[ dateIdx ] = tmpData['imp']
             revenueArr[ dateIdx ] = math_round(tmpData['revenue'] * (10 ^ sigFig)) / (10 ^ sigFig)

             dateArr.push(tmpData['date'])
          }
        }
        
        //console.log('_ _ _ _ _ _')
        //console.log({store})
        //console.log({chartData, dateDict, dateArr, adreqArr, monadreqArr, impArr, revenueArr, mundialSummary})
        //console.log({mundialData, dateDict, dateArr, adreqArr, monadreqArr, impArr, revenueArr, mundialSummary})
        //console.log('_ _ _ _ _ _')

        this.statsCards[0].value = ''
        this.statsCards[1].value = ''
        this.statsCards[2].value = ''
        this.statsCards[3].value = '$'
        this.statsCards[0].value += mundialSummary['adreq'].toString()
        this.statsCards[1].value += mundialSummary['mon_adreq'].toString()
        this.statsCards[2].value += mundialSummary['imp'].toString()
        this.statsCards[3].value += mundialSummary['revenue'].toFixed(2).toString()

        // Update Footer text
        this.statsCards[0].footerText = irisText
        this.statsCards[1].footerText = irisText
        this.statsCards[2].footerText = irisText
        this.statsCards[3].footerText = irisText
	
        //
        // Replace missing dates
        //
        //console.log({dateArr, dateArrLength:dateArr.length - 1, eDate:new Date(this.reformatDate( dateArr[dateArr.length - 1] ))})

        const sDate = new Date( this.reformatDate(dateArr[0]) ), eDate = new Date( this.reformatDate( dateArr[dateArr.length - 1]) ),
        diffDays = eDate ? store.dateDiffDays(sDate, eDate) : 0, boolCheck = !(dateArr.length) || (dateArr.length === diffDays + 1);

        if (!boolCheck) {
          const cleanDateArr = store.dateRangeArr(sDate, diffDays + 1),
          cleanmonadreqArr = new Array(diffDays + 1).fill(NaN),
          cleanimpArr = new Array(diffDays + 1).fill(NaN),
          cleanrevenueArr = new Array(diffDays + 1).fill(NaN);

          for (let i = 0; i < cleanDateArr.length; i++) {
            const cleanDate = cleanDateArr[i],
            tmpDate = [cleanDate.split('-')[1], cleanDate.split('-')[2], cleanDate.split('-')[0]].join('-'),
            dateBool = tmpDate in dateDict,
            tmpMonAdreq = dateBool ? monadreqArr[ dateDict[tmpDate] ] : false,
            tmpImp = dateBool ? impArr[ dateDict[tmpDate] ] : false,
            tmpRevenue = dateBool ? revenueArr[ dateDict[tmpDate] ] : false;

            //console.log({dateBool, tmpDate, tmpMonAdreq, tmpImp, tmpRevenue})

            if (tmpMonAdreq) cleanmonadreqArr[i] = tmpMonAdreq;
            if (tmpImp) cleanimpArr[i] = tmpImp;
            if (tmpRevenue) cleanrevenueArr[i] = tmpRevenue;
          }

          dateArr = cleanDateArr;
          monadreqArr = cleanmonadreqArr; 
          impArr = cleanimpArr;
          revenueArr = cleanrevenueArr;

          //console.log({dateArr, monadreqArr, impArr, revenueArr})
        }

        //
        // Set Min Range
        //
        if (diffDays < 3) {
          //new Date(filterMin.getTime() - 3600 * 1000 * 24)

          const newRange = 5, newOffset = Math.floor((newRange - diffDays)/2),
          newDate = new Date( sDate.getTime() - 3600 * 1000 * 24 * newOffset ), 
          cleanDateArr = store.dateRangeArr(newDate, 5),
          cleanmonadreqArr = new Array(newRange).fill(NaN),
          cleanimpArr = new Array(newRange).fill(NaN),
          cleanrevenueArr = new Array(newRange).fill(NaN);

          for (let i = 0; i < dateArr.length; i++) {
            const tmpDate = dateArr[i],
            dateBool = tmpDate in dateDict,
            tmpMonAdreq = dateBool ? monadreqArr[ dateDict[tmpDate] ] : false,
            tmpImp = dateBool ? impArr[ dateDict[tmpDate] ] : false,
            tmpRevenue = dateBool ? revenueArr[ dateDict[tmpDate] ] : false;

            //console.log({dateBool, tmpDate, tmpMonAdreq, tmpImp, tmpRevenue})

            if (tmpMonAdreq) cleanmonadreqArr[i + newOffset] = tmpMonAdreq;
            if (tmpImp) cleanimpArr[i + newOffset] = tmpImp;
            if (tmpRevenue) cleanrevenueArr[i + newOffset] = tmpRevenue;
          }

          dateArr = cleanDateArr;
          monadreqArr = cleanmonadreqArr; 
          impArr = cleanimpArr;
          revenueArr = cleanrevenueArr;

          //console.log({sDate,eDate,newDate,diffDays,cleanDateArr,cleanmonadreqArr,cleanimpArr,cleanrevenueArr,newOffset})
        }

        //
        // Matched AdReqs
        //
        const monAdReqData = {},
        monAdReqDataset = JSON.parse(JSON.stringify(irisDataset));

        monAdReqDataset['spanGaps'] = true
        monAdReqDataset['borderColor'] = '#2ba9cd'
        monAdReqDataset['pointBackgroundColor'] = '#2ba9cd'
        monAdReqDataset['label'] = 'Monetizable Requests'
        monAdReqDataset['data'] = monadreqArr

        monAdReqData['labels'] = dateArr
        monAdReqData['datasets'] = [ monAdReqDataset ]
        this.matchedAdReqChart.data = monAdReqData

        //
        // Impressions
        //
        const impData = {},
        impDataset = JSON.parse(JSON.stringify(irisDataset));

        impDataset['spanGaps'] = true
        impDataset['borderColor'] = '#ef8156'
        impDataset['pointBackgroundColor'] = '#ef8156'
        impDataset['label'] = 'Impressions'
        impDataset['data'] = impArr

        impData['labels'] = dateArr
        impData['datasets'] = [ impDataset ]
        this.impChart.data = impData

        //
        // Revenue
        //
        const revenueData = {},
        revenueDataset = JSON.parse(JSON.stringify(irisDataset));

        revenueDataset['spanGaps'] = true
        revenueDataset['label'] = 'Revenue'
        revenueDataset['data'] = revenueArr

        revenueData['labels'] = dateArr
        revenueData['datasets'] = [ revenueDataset ]
        this.revenueChart.data = revenueData
      },
      async setMinMaxIris() {
        const mundial = store.irisData.mundial, minIdx = 0, maxIdx = mundial.length - 1,
        tmpMin = new Date(mundial.length ? mundial[minIdx].date : store.irisDateRange[0]),
        tmpMax = new Date(mundial.length ? mundial[maxIdx].date : store.irisDateRange[1]),
        minDate = tmpMin < tmpMax ? tmpMin : tmpMax, maxDate = tmpMax > tmpMin ? tmpMax : tmpMin,
        filters = this.filters, filterMin = new Date(filters[0][1][0]), filterMax = new Date(filters[0][1][1]),
        customBool = store.customMinMaxDate.length,
        customMin = customBool ? store.customMinMaxDate[0] : false,
        customMax = customBool ? store.customMinMaxDate[1] : false,
        rangeBool = filterMin > customMin && filterMax < customMax,
        queryBool = customBool ? !rangeBool : false,
        sessionBool = store.irisData.sessionFilters;

        // Initial Min-Max
        if (!store.irisMinMaxDate.length) {
          store.irisMinMaxDate = [ minDate, maxDate ]
        }

        // Check for custom dateRange ( customRange )
        if (!customBool && filterMin < minDate) {
          //console.log({customBool})
          const adjMin = new Date(filterMin.getTime() - 3600 * 1000 * 24);
          store.customMinMaxDate = [ adjMin, filterMax ]
          //store.customMinMaxDate = [ filterMin, filterMax ]
          store.customQuery = true
          store.irisData.custom = await store.customMundial()
        }

        // Check if filterRange outside existing customRange
        if (queryBool && filterMin < minDate) {
          //console.log({queryBool})
          store.customMinMaxDate = [ filterMin, filterMax ]
          store.customQuery = true
          store.irisData.custom = await store.customMundial()
        }

        // Check if filterRange inside existing customRange
        if (customBool && rangeBool) {
          //store.customMinMaxDate = [ filterMin, filterMax ]
          store.customQuery = true
        }

        // Reset if within irisData.mundial
        if (filterMin > minDate) {
          store.customQuery = false
        }

        // Adjust customRange outside initial irisRange
        if (queryBool && sessionBool) {
          store.irisData.adjust_custom = true
        }

        //console.log({minIdx, maxIdx, minDate, maxDate, mundial, filterMin, filterMax, filters, mundialData:store.mundialData, customData:store.irisData.custom})
      },
      async setSummaryData() {
        store.irisLoader = true
        this.resetFilters()
        await this.setMinMaxIris()

        //console.log({filters:this.filters,mundial:store.irisData.mundial})
        //console.log({store})

        if(this.filters.length){
          //store.mundialData = JSON.parse(JSON.stringify(store.filterMundial(this.filters)))

          store.mundialData = (JSON.parse(JSON.stringify(store.filterMundial(this.filters)))).sort(function(x, y){
            const date1 = new Date(x.date),
            date2 = new Date(y.date);
            return date1 - date2;
          })
      
          //store.chartData = JSON.parse(JSON.stringify(store.filterCharts(this.filters)))
        } else {
          //store.mundialData = JSON.parse(JSON.stringify(store.customQuery ? store.irisData.custom : store.irisData.mundial))

          store.mundialData = (JSON.parse(JSON.stringify(store.customQuery ? store.irisData.custom : store.irisData.mundial))).sort(function(x, y){
            const date1 = new Date(x.date),
            date2 = new Date(y.date);
            return date1 - date2;
          })

          //store.chartData = JSON.parse(JSON.stringify(store.irisData.mundial_charts))
        }

        //console.log({mundialData:store.mundialData})
  
        this.compileSummaryCharts()
        this.irisSessionFilters()
        store.irisLoader = false
      },
      irisSessionFilters() {
        const sessionFilters = {},
        irisFilters = this.filters,
        sessionBool = store.irisData.sessionFilters,
        sessMin = sessionBool ? sessionBool.filters[0][1][0] !== irisFilters[0][1][0] : false,
        sessMax = sessionBool ? sessionBool.filters[0][1][1] !== irisFilters[0][1][1] : false,
        newRange = sessMin && sessMax,
        customBool = sessionBool ? newRange : true;

        //console.log({sessionBool,irisFilters})

        // Session filters (Django)
        sessionFilters['filters'] = irisFilters
        sessionFilters['irisText'] = store.irisText
        sessionFilters['aggregateSelected'] = store.aggregateSelected

        // Modify if customRange (outside irisRange)
        if (store.irisText === 'Custom Range' && customBool) {
          const customQuery = store.customQuery ? 0 : 1, tmpMin = new Date(sessionFilters['filters'][0][1][0]), strMin = new Date(tmpMin.getTime() + 3600 * 1000 * 24).toISOString().split('T')[0],
          tmpMax = new Date(sessionFilters['filters'][0][1][1]), strMax = new Date(tmpMax.getTime() + 3600 * 1000 * 24).toISOString().split('T')[0];

          sessionFilters['filters'][0][1] = [strMin, strMax]
        }

        //console.log({sessionFilters})

        this.setSessionFilters(sessionFilters)
      },
      resetFilters() {
        const dateRange = [store.irisDateRange[0], store.irisDateRange[1]],
        productType = store.productSelected, productSubtype = store.subProductSelected,
        billingEntity = store.companySelected,
        siteName = store.siteSelected;

        // Data filters (VueJS)
        this.filters = []
        this.filters.push(['date', dateRange])
        this.filters.push(['product_type', productType])
        this.filters.push(['product_subtype', productSubtype])
        this.filters.push(['billing_entity', billingEntity])
        this.filters.push(['site_name', siteName])
      },
      resetIrisData() {
        store.irisText = 'Last 30 Days'
        store.irisDateRange = this.store.ogIrisDateRange
        store.companySelected = 'all'
        store.siteSelected = 'all'
        store.productSelected = 'all'
        store.subProductSelected = 'all'
        store.aggregateSelected = 'total'

        this.setSummaryData()
      },
      async setSessionFilters(params) {
        //console.log({params,store})
        await store.resetSessionFilters(params)
      },
    },
    async mounted () {
      await store.initMundial()

      // Add router to push to Login page upon expired session
      // Consider moving router to router.js (currently main.js)
      if (store.irisData) {
        store.session_router = this.$router

        // Adjust sessionRange dates only when outside irisRange
        store.irisData.adjust_custom = false
      }

      // Filter variables
      const lastThirty = irisDate.GetLastThirty(),
      sDate = lastThirty['startDay'].toISOString().split('T')[0],
      eDate = lastThirty['endDay'].toISOString().split('T')[0],
      sessionFilters = store.irisData.sessionFilters;
      
      if (sessionFilters) {
        this.filters = sessionFilters.filters
        store.irisText = sessionFilters.irisText
        store.aggregateSelected = sessionFilters.aggregateSelected

        const filters = this.filters, filterLen = filters.length;
        for (let f = 0; f < filterLen; f++) {
          const tmpFilter = filters[f],
          tmpKey = tmpFilter[0],
          storeKey = storeNameDict[tmpKey];
          let tmpVal = tmpFilter[1];
          
          if (tmpKey === 'date' && store.irisText === 'Custom Range' && store.customQuery) {
            //const tmpMin = tmpVal[0], tmpMax = tmpVal[1];

            const tmpMin = new Date(tmpVal[0]), tmpMax = new Date(tmpVal[1]),
            adjMin = new Date(tmpMin.getTime() + 3600 * 1000 * 24),
            adjMax = new Date(tmpMax.getTime() + 3600 * 1000 * 24);
            //adjMin = new Date(tmpMin.getTime() + 3600 * 1000 * 24).toISOString().split('T')[0],
            //adjMax = new Date(tmpMax.getTime() + 3600 * 1000 * 24).toISOString().split('T')[0];

            //console.log({tmpMin,tmpMax,tmpVal,adjMin,adjMax})
            tmpVal = [adjMin, adjMax]

            //tmpVal = [tmpMin, tmpMax]
          }
          
          store[storeKey] = tmpVal 
        }
      } else {
        store.irisDateRange = [sDate, eDate]        
      }

      store.ogIrisDateRange = [sDate, eDate]
      this.setSummaryData()

      //console.log({store})
    },
    computed: {
      irisData () {
        return store.irisData
      },
      startDate () {
        return store.irisDateRange.length ? this.store.irisDateRange[0] : ''
      },
      endDate () {
        return store.irisDateRange.length ? this.store.irisDateRange[1] : ''
      },
      matchedAdReqChartData () {
        return this.matchedAdReqChart.data
      },
      impChartData () {
        return this.impChart.data
      },
      revenueChartData () {
        return this.revenueChart.data
      },
      siteOptions () {
        const companySelected = store.companySelected === 'all' ? false : store.irisData.be_options.filter(obj => obj.label.toLowerCase() === store.companySelected)[0],
        be_id = companySelected ? companySelected.value : false,
        be_sn_set = be_id ? new Set(store.irisData.be_sn_dict.filter(obj => obj.be_id === be_id)[0].sn_id.split(',')) : false;

        //if (be_sn_set) {
          //store.siteSelected = 'all'
          //this.setSummaryData()
        //}

        return be_sn_set ? store.irisData.site_options.filter(obj => be_sn_set.has(obj.value.toString())) : store.irisData.site_options
      },
      subProductOptions () {
        const productSelected = store.productSelected === 'all' ? false : store.irisData.product_options.filter(obj => obj.label.toLowerCase() === store.productSelected)[0],
        product_id = productSelected ? productSelected.value : false,
        subproduct_list = store.irisData.product_subproduct_dict.filter(obj => obj.product_type_id === product_id),
        product_subproduct_set = product_id && subproduct_list.length ? new Set(subproduct_list[0].product_subtype_id.split(',')) : false;

        //if (product_subproduct_set) {
          //store.subProductSelected = 'all'
          //this.setSummaryData()
        //}

        return product_subproduct_set ? store.irisData.subproduct_options.filter(obj => product_subproduct_set.has(obj.value.toString())) : product_id && !subproduct_list.length ? [] : store.irisData.subproduct_options
      },
    },
  }

</script>
<style>
.floatRight {
    right: 0;
    text-align: right;
    padding-left: 15px;
    padding-right: 15px;
    left: auto !important;
    min-width: 100% !important;
}
.irisBorders {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.irisHeader {
    border-top: none;
    margin-top: -1em;
    margin-bottom: 3em;
}
.iris-default.el-select .el-input input {
    background-color: #fff;
    border-color: #ddd!important;
    border-width: 2px;
    border-radius: 12px;
    color: #66615b;
}
.el-date-editor .el-range__close-icon {
    display: none;
}
.select-default.el-select .el-input .el-input__icon {
    border-radius: 20px;
    height: 36px;
    width: 30px;
    line-height: 36px;
}
.select-default.el-select-dropdown__item.selected, .select-default.el-select-dropdown__item.selected.hover {
    background-color: #ddd;
    color: #66615b;
}
.el-range-editor.is-active, .el-range-editor.is-active:hover {
    border-color: #9a9a9a;
}
.daterange-iris {
    border-radius: 12px !important;
    border: 2px solid #ddd !important;
}
.iris-vcenter {
    display: table;
}
.iris-vcenter button {
    margin-top: 1em;
}
.iris-vcenter > .iris-a {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}
.iris-vcenter > .btn-iris-reset {
    float: right;
}
.btn-iris-reset:hover, .btn-iris-reset:focus {
    background-color: #66615b !important;
}
.btn-iris-avails:hover, .btn-iris-avails:focus {
    background-color: #51bcda !important;
}
.systemStatus {
    display: block;
    text-align: center !important;
    background-color: #EDD14C !important;
    padding: 0.25em;
    font-weight: bolder;
    margin-top: -40px;
}
</style>
